import React from "react";

const NPMLogo = ({ classProp, width, height }) => (
  <svg
    version="1.1"
    height="inherit"
    viewBox="0 0 576 512"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    className={classProp}>
    <title>NPM</title>
    <path d="M288 288h-32v-64h32v64zm288-128v192H288v32H160v-32H0V160h576zm-416 32H32v128h64v-96h32v96h32V192zm160 0H192v160h64v-32h64V192zm224 0H352v128h64v-96h32v96h32v-96h32v96h32V192z" />
  </svg>
);
export default NPMLogo;
