import React from "react";

const CSharpLogo = ({ classProp, width, height }) => (
  <svg
    version="1.1"
    height="inherit"
    viewBox="0 0 107.94 123.7"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={classProp}>
    <title>C#</title>
    <path d="m107.5 31.3 0.3-0.2c-0.6-1.1-1.5-2.1-2.4-2.6l-48.3-27.8c-0.8-0.5-1.9-0.7-3.1-0.7s-2.3 0.3-3.1 0.7l-48 27.9c-1.7 1-2.9 3.5-2.9 5.4v55.7c0 1.1 0.2 2.3 0.9 3.4l-0.2 0.1c0.5 0.8 1.2 1.5 1.9 1.9l48.2 27.9c0.8 0.5 1.9 0.7 3.1 0.7s2.3-0.3 3.1-0.7l48-27.9c1.7-1 2.9-3.5 2.9-5.4v-55.8c0.1-0.8 0-1.7-0.4-2.6zm-53.5 70c-21.8 0-39.5-17.7-39.5-39.5s17.7-39.5 39.5-39.5c14.7 0 27.5 8.1 34.3 20l-13 7.5c-4.2-7.5-12.2-12.5-21.3-12.5-13.5 0-24.5 11-24.5 24.5s11 24.5 24.5 24.5c9.1 0 17.1-5 21.3-12.4l12.9 7.6c-6.8 11.8-19.6 19.8-34.2 19.8zm51-41.5h-3.2l-0.9 4h4.1v5h-5l-1.2 6h-4.9l1.2-6h-3.8l-1.2 6h-4.8l1.2-6h-2.5v-5h3.5l0.9-4h-4.4v-5h5.3l1.2-6h4.9l-1.2 6h3.8l1.2-6h4.8l-1.2 6h2.2zm-12.7 4h3.8l0.9-4h-3.8z" />
  </svg>
);
export default CSharpLogo;
