import React from "react";

const CSS3Logo = ({ classProp, width, height }) => (
  <svg
    height="inherit"
    version="1.1"
    viewBox="0 0 88.66 125.54"
    xmlns="http://www.w3.org/2000/svg"
    className={classProp}>
    <title>CSS</title>
    <path
      fill="currentColor"
      d="m0 25 8.069 90.493 36.206 10.05 36.307-10.063 8.078-90.48zm69.21 50.488-2.35 21.892 9e-3 1.875-22.539 6.295v1e-3l-0.018 0.015-22.719-6.225-1.537-17.341h11.141l0.79 8.766 12.347 3.295-4e-3 0.015v-0.032l12.394-3.495 1.308-14.549h-25.907l-0.222-2.355-0.506-5.647-0.265-2.998h27.886l1.014-11h-42.473l-0.223-2.589-0.506-6.03-0.265-3.381h55.597l-0.267 3.334-2.685 30.154m0.12-62.114-7.149-8.374h7.149v-5h-16v4.363l8.39 7.637h-8.39v5h16zm-19 0-6.807-8.374h6.807v-5h-15v4.363l7.733 7.637h-7.733v5h15zm-18-1.374h-8v-7h8v-5h-14v17h14z"
    />
  </svg>
);
export default CSS3Logo;
