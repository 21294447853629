import React from "react";

const HTML5Logo = ({ classProp, width, height }) => (
  <svg
    version="1.1"
    height="inherit"
    viewBox="0 0 88.862 124.97"
    xmlns="http://www.w3.org/2000/svg"
    className={classProp}>
    <title>HTML</title>
    <path
      fill="currentColor"
      d="m0 25.373 8.087 89.919 36.289 9.682 36.39-9.499 8.096-90.102zm72.041 20.471-0.507 5.834-0.223 2.695h-42.569l1.017 12h40.54l-0.271 2.231-2.615 28.909-0.192 1.69-22.79 6.134v-5e-3l-0.027 0.012-22.777-5.916-1.546-17.055h11.168l0.791 8.46 12.385 3.139 6e-3 -0.234v0.012l12.412-2.649 1.296-13.728h-38.555l-2.734-30.836-0.267-3.164h55.724zm-63.654-45.844h5.622v5.556h5.144v-5.556h5.623v16.822h-5.623v-5.633h-5.143v5.633h-5.623zm23.782 5.579h-4.95v-5.579h15.525v5.579h-4.952v11.243h-5.623zm13.039-5.579h5.862l3.607 5.911 3.603-5.911h5.865v16.822h-5.601v-8.338l-3.867 5.981h-0.098l-3.87-5.981v8.338h-5.502v-16.822zm21.736 0h5.624v11.262h7.907v5.561h-13.531z"
    />
  </svg>
);
export default HTML5Logo;
